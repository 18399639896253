import React from 'react'
import { useTranslation } from 'react-i18next';

const LastNews = () => {

    const { t } = useTranslation("global");

    return (
        <section className='w-full mt-20'>
            <div className="relative grid grid-cols-1 lg:grid-cols-2 items-center gap-6 md:px-10">
                <img
                    src="/images/network.webp"
                    alt="2nd background"
                    className="absolute -z-10 top-0 h-96 left-0 opacity-20 w-full object-cover"
                />

                <div>
                    <div className="flex flex-col items-center text-center lg:items-start lg:text-start">
                        <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold font-poppins mb-8 lg:mb-12 mx-4 '>
                            {t('news.title')}
                            <span className="text-blue-600">{t('news.titleHightlight')}</span>
                        </h1>
                        <p className='text-xs sm:text-sm md:text-base w-[98%] max-lg:text-center mx-auto max-lg:px-6'>
                            {t('news.text')}
                        </p>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-7 items-center">
                    {
                        t('news.aiInsights', { returnObjects: true }).map(insight => (
                            <article key={insight.id} className="w-full md:max-w-[30rem] lg:max-w-[36rem] max-lg:px-8">
                                <article className="flex flex-col items-center md:items-start lg:flex-row lg:items-center lg:gap-5">
                                    <img src={insight.icon} className='w-10 md:w-12' alt={insight.title} />
                                    <div className="flex flex-col text-center md:text-start space-y-1">
                                        <h4 className="font-bold text-base">{insight.title}</h4>
                                        <p className="text-sm leading-6">{insight.content}</p>
                                    </div>
                                </article>
                            </article>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default LastNews
