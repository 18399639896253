import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Header = () => {

    const { t } = useTranslation("global");

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className='homeHeader'>

            <div className="imgsContainer">
                <img src="/images/robotHand.webp"
                    loading="eager"
                    alt="Robot Hand"
                    className='robotHand' style={{ right: `${scrollPosition / 5}px` }} />

                <img src="/images/humanHand.webp"
                    loading="eager"
                    alt="Human Hand"
                    className='humanHand' style={{ left: `${scrollPosition / 5}px` }} />

                <div className="sloganContainer">

                    <h1 className='slogan'
                        style={{ fontSize: "clamp(1.5rem, 0.5rem + 5vw, 4.5rem)" }}>
                        {t('header.sloganStart')}
                        <span className='text-blue-600'>{t('header.sloganHighlight')}</span>
                        {t('header.sloganEnd')}
                        <span className='text-blue-600'>{t('header.2ndSloganHighlight')}</span>
                    </h1>
                </div>

            </div>
        </header>
    )
}

export default Header

