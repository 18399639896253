import { Footer, Navbar, NotFound } from './components';
import { About, ContactPage, Home, ServicesPage } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom'


function App() {
  return (
    <div className='max-w-screen-xl mx-auto overflow-hidden'>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
