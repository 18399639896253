import React from 'react'
import { useTranslation } from 'react-i18next';

const Presentation = () => {

    const { t } = useTranslation("global");

    return (
        <section className="w-full bg-whiteTheme pt-20 md:pb-4">

            <h1 className='title '>
                {t('presentation.presStart')}
                <span className='text-blue-600'>{t('presentation.presHighlight')}</span>
                {t('presentation.presEnd')}
            </h1>

            <p className='text-xs sm:text-sm md:text-base lg:text-xl w-10/12 text-center mx-auto'>
                {t('presentation.presText')}
            </p>

        </section>
    )
}

export default Presentation