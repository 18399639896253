import React from 'react'
import { logos } from '../constants';

const Partners = () => {

    const logosList = logos.map((logo, index) => (
        <img key={index} src={logo} alt={`Logo ${index}`} />
    ));

    return (
        <section className='w-full bg-whiteTheme py-10'>
            <h1 className="title">Our Partnerships</h1>
            <div className="logos">
                <div className="logos-slide">
                    {logosList}
                </div>
                <div className="logos-slide">
                    {logosList}
                </div>
            </div>
        </section>
    );
}

export default Partners;