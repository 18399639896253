import React, { useEffect } from 'react'
import '../components/styles.css';
import { Contact } from '../components';

const ContactPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Inovasys - Contact';

    }, []);

    return (
        <div>
            <Contact />

            <iframe className='w-11/12 sm:w-9/12 rounded-lg mx-auto mt-24'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924.4896600936768!2d-6.869889155250104!3d33.96108087859324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7132d2a3728ab%3A0x22a9ca17b33c74ae!2sInstitut%20Sp%C3%A9cialis%C3%A9s%20dans%20les%20M%C3%A9tiers%20de%20l&#39;Offshoring%20et%20les%20Nouvelles%20Technologies%20de%20l&#39;Information_Rabat!5e1!3m2!1sfr!2sma!4v1710884491157!5m2!1sfr!2sma" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Inovasys Location'>
            </iframe>
        </div>
    )
}

export default ContactPage