import React from 'react'

const NotFound = () => {
    return (
        <div>
            <img
                className='h-[90vh] mx-auto -mb-20'
                loading="eager"
                src="/images/404.svg"
                alt="Not Found"
            />
        </div>
    )
}

export default NotFound