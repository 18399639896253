import React, { useEffect, useState } from 'react';
import { achievements } from "../constants"

const Achievements = () => {

    const [gNums, setGNums] = useState({
        num0: 0, num1: 0, num2: 0,
    });

    const data = [
        { "num": 100 },
        { "num": 200 },
        { "num": 300 }
    ]

    const myCountNum = () => {
        setGNums(prevState => ({
            ...prevState,
            num0: prevState.num0 < data[0].num ? prevState.num0 + 1 : data[0].num,
            num1: prevState.num1 < data[1].num ? prevState.num1 + 2 : prevState.num1,
            num2: prevState.num2 < data[2].num ? prevState.num2 + 3 : prevState.num2,
        }))
    }

    useEffect(() => {
        const inter = setInterval(myCountNum, 30);
        return () => {
            clearInterval(inter)
        }
    }, []);

    const nums = data.map((myNum, index) => (
        <h1 key={index}>{gNums[`num${index}`]}</h1>
    ))

    return (
        <section className='w-full px-5 pt-28 pb-16'>
            <div className="absolute z-[0] w-[50%] h-[50%] right-[85%] -top-20 blue__gradient" />

            <h1 className='title'>Our Achievements</h1>

            <div className='flex justify-center gap-3 flex-wrap pt-5 '>
                {
                    achievements.map(achievement => (
                        <div key={achievement.id}
                            className='flex md:flex-1 max-md:w-[300px] flex-col items-center gap-3 mb-6'>
                            <div className='w-24 bg-blue-600 rounded-3xl p-6 shadow-2xl'>
                                <img src={achievement.logo} alt="Achievements Logo" />
                            </div>
                            <h1 className='flex gap-1 text-2xl font-semibold'>
                                <span>{nums[achievement.id]}</span>
                                <span>{achievement.title}</span>

                            </h1>
                            <p className='text-gray-800 w-10/12 text-center'>{achievement.description}</p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default Achievements