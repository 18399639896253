import React from 'react'
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation("global");

    const currentYear = new Date().getFullYear();

    return (
        <section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200" className='-mb-[2px]'>
                <path fill="#0a1237" fillOpacity="1" d="M0,128L48,117.3C96,107,192,85,288,85.3C384,85,480,107,576,128C672,149,768,171,864,181.3C960,192,1056,192,1152,186.7C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
            <footer className="bg-[#0a1237] w-full px-3 md:px-10 lg:px-14 py-5">
                <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-between max-sm:gap-10">
                    {/* left */}
                    <div className='flex flex-col max-sm:gap-6 justify-between items-center sm:h-[145px] mr-2'>
                        <img src="/images/whiteLogo.webp" alt="Inovasys Logo" className='w-32 sm:w-44' />
                        <div className='flex gap-3'>
                            <img src="/icons/linkedIn.svg" alt="LinkedIn" className='w-3 sm:w-[18px] opacity-75 hover:opacity-100 hover:cursor-pointer' loading='lazy' />
                            <img src="/icons/instagram.svg" alt="instagram" className='w-3 sm:w-[18px] opacity-75 hover:opacity-100 hover:cursor-pointer' loading='lazy' />
                            <img src="/icons/github.svg" alt="github" className='w-3 sm:w-[18px] opacity-75 hover:opacity-100 hover:cursor-pointer' loading='lazy' />
                        </div>
                    </div>

                    {/* right */}
                    <div className="flex flex-row justify-center gap-6 md:gap-12 lg:gap-20 max-sm:text-xs max-[410px]:text-center flex-wrap">
                        <div>
                            <h2 className="mb-3 sm:mb-5 text-xs sm:text-sm font-semibold uppercase text-white">Pages</h2>
                            <ul className="text-gray-400 flex flex-col gap-1">
                                {
                                    t('navLinksT', { returnObjects: true }).map(navLink => (
                                        <li key={navLink.id}
                                            className='hover:underline hover:cursor-pointer'>
                                            {navLink.title}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-3 sm:mb-5 text-xs sm:text-sm font-semibold uppercase text-white">{t(`footer.contact`)}</h2>
                            <ul className="text-gray-400 flex flex-col gap-1">
                                <li>
                                    <p className="hover:underline ">contact@inovasys.com</p>
                                </li>
                                <li>
                                    <p className="hover:underline">05 35 00 00 00</p>
                                </li>
                                <li>
                                    <p className="hover:underline">05 39 99 99 99</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-3 sm:mb-5 text-xs sm:text-sm font-semibold uppercase text-white">{t(`footer.legal`)}</h2>
                            <ul className="text-gray-400 flex flex-col gap-1">
                                <li>
                                    <p className="hover:underline hover:cursor-pointer">{t(`footer.legalPolicy`)}</p>
                                </li>
                                <li>
                                    <p className="hover:underline hover:cursor-pointer">{t(`footer.legalCondition`)}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr className='my-6 mx-3 opacity-80' />

                <div className="w-full text-center text-gray-200">
                    <h1 style={{ fontSize: "clamp(0.75rem, 0.6667rem + 0.4167vw, 1rem)" }}>
                        &copy; {currentYear}
                        <span className='font-semibold'>Inovasys™</span>.
                        {t(`footer.rights`)}
                    </h1>
                </div>

            </footer>
        </section>

    )
}

export default Footer