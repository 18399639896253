import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Navbar = () => {

    const [toggle, setToggle] = useState(false);
    const [langVisibility, setLangVisibility] = useState(0)

    const navToggle = (nav) => {
        setToggle(false);
    }

    const [t, i18n] = useTranslation("global");

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <nav className='nav'>
            <section className="nav-section pxxl">
                <div className='flex items-center'>
                    <Link to="/">
                        < img src="/images/Logo.webp" alt="Brand Logo" className='w-32 sm:w-40' />
                    </Link>
                </div>
                <div className='hidden md:block'>
                    <ul className='flex gap-8'>
                        {
                            t('navLinksT', { returnObjects: true }).map(navLink => (
                                <li key={navLink.id}
                                    className='nav-links'>
                                    <Link to={navLink.link}>
                                        {navLink.title}
                                    </Link>
                                </li>
                            ))
                        }

                        <div className='flex'>
                            <img src="/icons/language.svg" alt="langaugeIcon"
                                className='w-6 cursor-pointer hover:rotate-12 duration-200'
                                onClick={() => setLangVisibility(!langVisibility)} />

                            <div className='lang-indicator'>{t('language')}</div>

                            <div className={`lang-dropdown top-14 ${!langVisibility && 'hidden'}`}>
                                <div className='lang-option' onClick={() => { handleChangeLanguage("en"); setLangVisibility(false); }}>
                                    <p>EN</p>
                                    <img src="/icons/en.svg" className='w-6' alt="en icon" />
                                </div>

                                <div className='lang-option' onClick={() => { handleChangeLanguage("fr"); setLangVisibility(false); }}>
                                    <p>FR</p>
                                    <img src="/icons/fr.svg" className='w-6' alt="fr icon" />
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>

                {/* Mobile Navbar */}

                <div className="md:hidden w-full  flex flex-1 justify-end items-center">
                    <img
                        src={toggle ? "/icons/closeMenu.svg" : "/icons/menu.svg"}
                        alt="menu"
                        className="w-[24px] h-[24px] object-contain"
                        onClick={() => setToggle(!toggle)}
                    />

                    <div
                        className={`${!toggle ? "hidden" : "flex"}
                        bg-transparent w-full absolute left-0 top-[70px] px-5  text-white sidebar`}
                    >

                        <ul className="list-none flex flex-col gap-1 bg-slate-900 w-full rounded-xl p-3 mx-auto">
                            {
                                t('navLinksT', { returnObjects: true }).map((navLink) => (
                                    <li
                                        key={navLink.id}
                                        className={`font-poppins cursor-pointer text-sm `}
                                        onClick={() => navToggle(navLink)}
                                    >
                                        <Link to={navLink.link}>
                                            {navLink.title}
                                        </Link>
                                    </li>
                                ))
                            }

                            <div className='flex'>
                                <img src="/icons/languageWhite.svg" alt="langaugeIcon" className='w-5 cursor-pointer hover:rotate-12 duration-200'
                                    onClick={() => setLangVisibility(!langVisibility)} />

                                <div className='lang-indicator text-black'>
                                    {t('language')}
                                </div>

                                <div className={`lang-dropdown top-[136px] text-black
                                    ${!langVisibility && 'hidden'}`}>

                                    <div className='lang-option'
                                        onClick={() => { handleChangeLanguage("en"); setLangVisibility(false); }}
                                    >
                                        <p>EN</p>
                                        <img src="/icons/en.svg" className='w-6' alt="en icon" />
                                    </div>

                                    <div className='lang-option'
                                        onClick={() => {
                                            handleChangeLanguage("fr");
                                            setLangVisibility(false)
                                        }}>
                                        <p>FR</p>
                                        <img src="/icons/fr.svg" className='w-6' alt="fr icon" />
                                    </div>

                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
        </nav >
    )
}

export default Navbar