import React, { useEffect } from 'react'
import { FadeImage, Services } from '../components'
import '../components/styles.css';

const ServicesPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Inovasys - Services';

    }, []);

    return (
        <>
            <FadeImage />
            <Services />
        </>
    )
}

export default ServicesPage