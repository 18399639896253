import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

import { servicesCards, servicesSection } from "../constants"


const Services = () => {

    return (
        <section className='w-full pt-32'>

            <h1 className="title">Some of our Services</h1>

            <Swiper
                slidesPerView={3}
                centeredSlides={true}
                loop={true}
                spaceBetween={0}
                navigation={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                    0: {
                        slidesPerView: 1, // 1 slide per view on mobile
                    },
                    700: {
                        slidesPerView: 2, // 2 slides per view on tablets
                    },
                    1024: {
                        slidesPerView: 3, // 3 slides per view on laptops/desktops
                    }
                }}
                className='max-w-[1150px] h-[320px] mx-auto p-5 swiper'
            >
                {servicesCards.map((card, index) => (
                    <SwiperSlide key={index} className='swiper-slide'>
                        <div className="swipeDiv">
                            <div className="slideDiv">
                                <img src={card.icon} alt="" className='mt-4 w-8 sm:w-10' />
                                <h2
                                    className="text-center text-lg sm:text-xl font-semibold mt-4">      {card.title}
                                </h2>

                                <p className="text-center text-gray-600 mt-2 max-sm:text-sm">
                                    {card.description}
                                </p>

                                <a href={card.link}>
                                    <button
                                        className='slideButton'>
                                        Voir Plus
                                    </button>
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className='mb-14'>
                {
                    servicesSection.map(serviceSection => (
                        <div
                            key={serviceSection.id}
                            className={`md:flex justify-between items-center gap-20 mx-8 md:mx-24 lg:mx-40 pt-[100px] ${!(serviceSection.id % 2) && "flex-row-reverse"}`}
                            id={serviceSection.title}>
                            <div>
                                <h1 className='font-medium text-2xl'>{serviceSection.title}</h1>
                                <p style={{ "fontSize": "clamp(0.875rem, 0.8125rem + 0.3125vw, 1.0625rem)" }}>{serviceSection.text}</p>
                            </div>
                            <img src={serviceSection.img} alt={serviceSection.title} loading='lazy'
                                className='min-w-[250px] w-8/12 lg:w-full mx-auto mt-8 rounded' />
                        </div>
                    ))
                }
            </div>
        </section >
    );
}

export default Services
