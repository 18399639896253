export const achievements = [
    {
        id: 0,
        logo: "/icons/award.png",
        title: "Title",
        description: "Lorem ipsum, Reiciendis beatae velit deleniti blanditiis quaerat dolorem, corrupti tempore hic sed quia."
    },
    {
        id: 1,
        logo: "/icons/award.png",
        title: "Title",
        description: "Lorem ipsum, Reiciendis beatae velit deleniti blanditiis quaerat dolorem, corrupti tempore hic sed quia."
    },
    {
        id: 2,
        logo: "/icons/award.png",
        title: "Title",
        description: "Lorem ipsum, Reiciendis beatae velit deleniti blanditiis quaerat dolorem, corrupti tempore hic sed quia."
    }
];

export const servicesCards = [
    {
        icon: "/icons/service.png",
        title: 'Business',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: "#Business"
    },
    {
        icon: "/icons/service.png",
        title: 'Education',
        description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: "#Education"
    },
    {
        icon: "/icons/service.png",
        title: 'Technology',
        description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.',
        link: "#Technology"
    },
    {
        icon: "/icons/service.png",
        title: 'Business',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: "#Business"
    },
    {
        icon: "/icons/service.png",
        title: 'Education',
        description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: "#Education"
    },
    {
        icon: "/icons/service.png",
        title: 'Technology',
        description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.',
        link: "#Technology"
    }
];

export const servicesSection = [
    {
        id: 1,
        title: "Business",
        text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime, ipsa minus odit doloribus voluptates numquam impedit deleniti amet architecto nam? Corrupti, mollitia dolor possimus nemo odit voluptatem molestias corporis asperiores",
        img: "/images/business.webp"
    },
    {
        id: 2,
        title: "Education",
        text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime, ipsa minus odit doloribus voluptates numquam impedit deleniti amet architecto nam? Corrupti, mollitia dolor possimus nemo odit voluptatem molestias corporis asperiores",
        img: "/images/education.webp"
    },
    {
        id: 3,
        title: "Technology",
        text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime, ipsa minus odit doloribus voluptates numquam impedit deleniti amet architecto nam? Corrupti, mollitia dolor possimus nemo odit voluptatem molestias corporis asperiores",
        img: "/images/tech.webp"
    },
]

export const logos = [
    "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-sva-scholarship-20.png",
    "https://www.freepnglogos.com/uploads/spotify-logo-png/spotify-icon-marilyn-scott-0.png",
    "https://www.freepnglogos.com/uploads/netflix-logo-0.png",
    "https://www.freepnglogos.com/uploads/adidas-logo-png-black-0.png",
    "https://www.freepnglogos.com/uploads/amazon-png-logo-vector/woodland-gardening-amazon-png-logo-vector-8.png",
    "https://www.freepnglogos.com/uploads/cisco-png-logo/cisco-music-png-logo-11.png",
    "https://www.freepnglogos.com/uploads/apple-logo-png/file-apple-logo-black-svg-wikimedia-commons-6.png"
];

export const stats = [
    {
        id: 1,
        title: "Tech Stack",
        value: 10,
    },
    {
        id: 2,
        title: "Projects",
        value: 30,
    },
    {
        id: 3,
        title: "Years of Experience",
        value: 3,
    },
];