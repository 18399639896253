import React, { useState, useEffect } from 'react';

const FadeImage = () => {

    const [imageIndex, setImageIndex] = useState(0);

    const images = [
        {
            id: 0,
            src: "/images/imgH1.webp",
        },
        {
            id: 1,
            src: "/images/imgH2.webp",
        },
        {
            id: 2,
            src: "/images/imgH3.webp",
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <>
            <div className='fadeContainer'>

                {
                    images.map(image => (
                        <img
                            key={image.id}
                            src={image.src}
                            alt="images fade"
                            className={`${imageIndex === image.id ? 'showing' : ''} imgFade brightness-50`}
                        />
                    ))
                }
                <h1 className='absolute text-white font-semibold'
                    style={{ fontSize: "clamp(1.5rem, 0.7rem + 5vw, 4.5rem)" }}
                >
                    Inovasys Services
                </h1>

            </div>

            <div className="dotsContainer">
                {
                    images.map(imageDot => (
                        <h1 key={imageDot.id}
                            className={`dots ${imageIndex === imageDot.id ? "text-white" : "text-white/60"}  `}
                            onClick={() => setImageIndex(imageDot.id)}>
                            .
                        </h1>
                    ))
                }
            </div>
        </>
    );
}

export default FadeImage;
