import React from 'react'

const Contact = () => {

    return (
        <div className='w-full h-[550px] md:h-[450px] bg-[#0a1237] flex justify-around items-center  px-4 sm:px-12 lg:px-20  mt-[70px]'>

            <form className="w-full max-w-6/12 text-sm max-md:w-10/12 max-sm:w-full">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2">
                            Full Name
                        </label>
                        <input
                            className="appearance-none block w-full bg-transparent text-white border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:border-white" type="text"
                            placeholder="Your full name" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2">
                            Email
                        </label>
                        <input className="appearance-none block w-full bg-transparent text-white border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:border-white" type="email" placeholder="email@example.com" />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2">
                            Message
                        </label>
                        <textarea
                            className="appearance-none block w-full bg-transparent text-white border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-white resize-none"
                            cols="30" rows="5" placeholder="Your message here">
                        </textarea>
                    </div>
                </div>

                <div className='w-full flex justify-center lg:justify-between flex-wrap gap-3 mb-6'>

                    <button className='w-8/12 sm:w-6/12 h-12 bg-blue-600 rounded-full text-white'>
                        Submit
                    </button>
                    <div className='flex items-center gap-3 text-white'>
                        <a href="#gmail">
                            <img src="/icons/gmail.svg" className='w-6 opacity-80 hover:opacity-100' alt="Gmail" />
                        </a>
                        <a href="#whatsapp">
                            <img src="/icons/whatsapp.svg" className='w-6 opacity-80 : hover:opacity-100' alt="Whatsapp" />
                        </a>
                        <p>inovasys@contact.com</p>
                    </div>

                </div>

                <p className='text-white text-center '>
                    123 Rue des technologies, Quartier Hay Ryad, Rabat - Maroc
                </p>
            </form>

            <iframe
                loading="eager"
                className='h-full w-11/12 max-md:hidden'
                src="https://lottie.host/embed/26470189-3417-4939-9bbb-e1c665a16434/gcFzhxnksw.json" title="Inovasys Contact">
            </iframe>
        </div>
    )
}

export default Contact