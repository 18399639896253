import React, { useEffect } from 'react'
import { Header, Presentation, LastNews } from '../components'
import '../components/styles.css';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />
            <Presentation />
            <LastNews />
        </div>
    )
}

export default Home