import React, { useEffect } from 'react'
import { Achievements, Partners } from '../components'
import '../components/styles.css';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Inovasys - About';
    }, []);

    return (
        <div>
            <Achievements />
            <Partners />
        </div>
    )
}

export default About